import React from "react";
import Layout from "../components/layout.js";
import { Location } from '@reach/router';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import grey from '@material-ui/core/colors/grey';

const getClasses = makeStyles(theme => ({
    result: {
      background: grey[50],
      height: 300,
    }
}));

const i18n = {
  'cn':{
    title: '下载完成',
    invalid: '无效下载链接',
    thanks: '感谢下载Nano，如果下载未正常启动，请点击',
    link: '此链接',
  },
  'en':{
    title: 'Download Finish',
    invalid: 'Invalid Link',
    thanks: 'Thanks for downloading. If the download does not start properly, please click ',
    link: 'this link',
  }

}

function DownloadResult(props){
  const classes = getClasses();
  const texts = i18n[props.lang];
  var params = props.queryString ? queryString.parse(props.queryString) : {};

  let content;
  if(!params.link){
    content = <Typography component='div'>{texts.invalid}</Typography>;
  }else{
    var link = decodeURIComponent(params.link);
    content = (
      <Typography component='div'>
        {texts.thanks}<Link href={link}>{texts.link}</Link>
      </Typography>
    );
  }
  return (
      <Layout title={texts.title} lang={props.lang}>
        <Container maxWidth="md">
          <Box display='flex' justifyContent="center" alignItems='center' m={2} className={classes.result}>
            <Box>
              {content}
            </Box>
          </Box>
        </Container>
      </Layout>
  );
}

export default function(props){
  const defaultLang = 'cn';
  const lang = props.pageContext.lang || defaultLang;
  return (
    <Location>
      {
        ({location}) => (
          <DownloadResult queryString={location.search} lang={lang}/>
        )
      }    
    </Location>
  )
}
